import React, { useEffect, useState } from 'react'
import World from '../../Components/World/World.js'
import Advertise from '../../Components/Advertise/Advertise.js'
import { Link } from 'react-router-dom'
import NewsSection from '../News/NewsSection.js'
import FeaturedArticles from '../../Components/FeaturedArticlesComponents/FeaturedArticles.js'
import { bannerAdds, getAllBox, getGroupedCategory, latestHomeThreeNews } from '../../../Services/UserServices.js'
import Loader from '../../../Components/Loader/Loader.js'
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { scrollToLastVisitedItem } from '../../../Helper/helper.js'
// import { scrollToLastVisitedItem } from '../../../Helper/helper.js'

function UserDasbboard() {

    const [latestNews, setLaststNews] = useState([]);
    const [bannerAds, setBannerAds] = useState([]);
    const [groupedCategory, setGroupedCategory] = useState([]);
    const [ads, setAds] = useState([]);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        window.scrollTo(0,0)
        try {
            await latestHomeThreeNews().then((res) => {
                let data = res?.data?.news;
                // Transform the content array
                // data.forEach(latest => {
                //     const transformedContent = [];
                //     let currentContent = {};
                //     latest?.content.forEach(item => {
                //         switch (item.type) {
                //             case 'header':
                //                 if (currentContent.header) {
                //                     transformedContent.push({ ...currentContent });
                //                     currentContent = {};
                //                 }
                //                 currentContent.header = item;
                //                 break;
                //             case 'image':
                //                 currentContent.image = item;
                //                 break;
                //             case 'paragraph':
                //                 currentContent.paragraph = item;
                //                 break;
                //             case 'Embed':
                //                 currentContent.Embed = item;
                //                 break;
                //             case 'attaches':
                //                 currentContent.attaches = item;
                //                 break;
                //             case 'linkTool':
                //                 currentContent.linkTool = item;
                //                 break;
                //             case 'list':
                //                 currentContent.list = item;
                //                 break;
                //             default:
                //                 break;
                //         }
                //     })
                //     if (Object.keys(currentContent).length !== 0) {
                //         transformedContent.push({ ...currentContent });
                //     }
                //     // Reassign the transformed content array back to the newsData object
                //     if (latest?.content) {
                //         latest.content = transformedContent;
                //     }
                // })
                setLaststNews(data);
            })
            await bannerAdds().then((res) => {
                let data = res?.data?.ads;
                setBannerAds(data);
            })
            await getGroupedCategory().then((res) => {
                let data = res?.data?.newsByCategory;
                // Object.keys(data).forEach((cat) => {
                //     data[cat].forEach((latest) => {
                //         const transformedContent = [];
                //         let currentContent = {};
                //         latest?.content.forEach(item => {
                //             switch (item.type) {
                //                 case 'header':
                //                     if (currentContent.header) {
                //                         transformedContent.push({ ...currentContent });
                //                         currentContent = {};
                //                     }
                //                     currentContent.header = item;
                //                     break;
                //                 case 'image':
                //                     currentContent.image = item;
                //                     break;
                //                 case 'paragraph':
                //                     currentContent.paragraph = item;
                //                     break;
                //                 case 'Embed':
                //                     currentContent.Embed = item;
                //                     break;
                //                 case 'attaches':
                //                     currentContent.attaches = item;
                //                     break;
                //                 case 'linkTool':
                //                     currentContent.linkTool = item;
                //                     break;
                //                 case 'list':
                //                     currentContent.list = item;
                //                     break;
                //                 default:
                //                     break;
                //             }
                //         })
                //         if (Object.keys(currentContent).length !== 0) {
                //             transformedContent.push({ ...currentContent });
                //         }
                //         // Reassign the transformed content array back to the newsData object
                //         if (latest?.content) {
                //             latest.content = transformedContent;
                //         }
                //     })
                // })
                setGroupedCategory(data);
            })
            await getAllBox().then((res) => {
                let data = res?.data?.ads;
                setAds(data);
            })
        } catch (err) {
            setLoading(false);
            console.log(err)
        } finally {
            setLoading(false);
            scrollToLastVisitedItem();
        }
    }

    useEffect(() => {
        getData();
    }, []);


    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <div style={{ paddingTop: 20 }}>
                <World latestNews={latestNews} />
            </div>
            <div style={{ paddingTop: 20 }}>
                <Advertise bannerAds={bannerAds} />
            </div>
            <div style={{ paddingTop: 20 }}>
                <NewsSection groupedCategory={groupedCategory} ads={ads} />
            </div>
            <div>
                <div className="slide-container">
                    {bannerAds.length > 1 ? (
                        <Zoom scale={0.4} defaultIndex={0}>
                            {bannerAds.map((each, index) => (
                                <Link to={each?.link} key={index}>
                                    <img style={{ width: "100%", height: 325, borderRadius: 5 }} src={each?.image?.url} alt={`Ad ${index + 1}`} />
                                </Link>
                            ))}
                        </Zoom>
                    ) : (
                        <Link to={bannerAds[0]?.link}>
                            <img style={{ width: "100%", height: 325, borderRadius: 5 }} src={bannerAds[0]?.image?.url} alt="Ad" />
                        </Link>
                    )}
                </div>
            </div>
            {/* <div style={{ paddingTop: 50 }}>
                <FeaturedArticles />
            </div> */}
            {/* <div style={{ paddingTop: 20 }}>
                <Link to="">
                    <img alt="complex" src={"/UserSideImages/img_ad11.jpg"} style={{ width: '100%', height: '100%' }} />
                </Link>
            </div> */}

        </div>
    )
}

export default UserDasbboard;