import { Box, Button, Card, TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from '@emotion/styled';

const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  padding: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },

  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%',// Adjust width for Mobiles
  },
  height: '100%'
}));
const Uploads = () => {
  const editorRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(800);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("")

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const url = canvas.toDataURL();
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName ? fileName : 'modified_image.png'; // Set the filename for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box component={card}>
      {image ? (
        <>
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={width}
            height={height}
            style={{ width: '100%', height: '500px' }}
          // scale={scale}
          />
          {/* <div>
            <input
              type="range"
              min="0.1"
              max="2"
              step="0.01"
              value={scale}
              onChange={(e) => setScale(parseFloat(e.target.value))}
            />
            <label>Scale: {scale}</label>
          </div> */}
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap',alignItems:'center' }}>
            <div>
              <TextField
                id="outlined-basic"
                label="Width"
                placeholder='Title'
                variant="outlined"
                sx={{ my: 5 }}
                value={width}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                  setWidth(isNaN(parseInt(numericValue)) ? 0 : parseInt(numericValue));
                }}
                inputProps={{
                  inputMode: 'numeric', // Restrict input to numeric characters
                  pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                }} />
            </div>
            <div>
              <TextField
                id="outlined-basic"
                label="Height"
                placeholder='Title'
                variant="outlined"
                sx={{ my: 5 }}
                value={height}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal point
                  setHeight(isNaN(parseInt(numericValue)) ? 0 : parseInt(numericValue));
                }}
                inputProps={{
                  inputMode: 'numeric', // Restrict input to numeric characters
                  pattern: '[0-9]*[.]?[0-9]*', // Pattern to allow decimal numbers
                }} />
            </div>
            <Box sx={{ my: 5 }}>
              <Button variant='contained' color="primary" onClick={handleSave}>Download</Button>
            </Box>
          </div>
        </>
      ) : (
        <div className="uploadBtn" style={{ width: "175px", height: "160px" }}>
          <input type="file" id="uploadBtn" onChange={handleFileChange} />
          <label htmlFor="uploadBtn" style={{ fontSize: 13 }}>
            Drop images / click to Upload
          </label>
        </div>
      )}
    </Box>
  );
};

export default Uploads;
