import React, { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';
import { Button, Card, Checkbox, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { deleteAdsById } from '../../../../../Services/AdminServices';
import moment from 'moment';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  borderRadius: '10px',
  padding: '10px',
  position: 'relative',
}));

export default function TitlebarImageList({ ads, getAllAd, selectedAds, onAdSelection }) {
  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [adsId, setAdsId] = useState("");

  const deleteDialogOpen = (id) => {
    setAdsId(id);
    setDeleteDialog(true);
  }

  const deleteDialogCancel = () => {
    setAdsId("");
    setDeleteDialog(false);
  }

  const deleteClick = async (id) => {
    try {
      const res = await deleteAdsById(id);
      toast.success(res?.data?.message);
      setTimeout(() => {
        getAllAd();
        deleteDialogCancel();
      }, 2000);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  return (
    <Grid container spacing={2}>
      {ads && ads.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
          <Item>
            <Checkbox
              checked={selectedAds.includes(item._id)}
              onChange={() => onAdSelection(item._id)}
              sx={{ position: 'absolute', top: 5, left: 5, zIndex: 1 }}
            />
            <PhotoProvider>
              <PhotoView src={item?.image?.url}>
                <div className="container">
                  <img src={item?.image?.url} alt="Avatar" className="image" />
                  <div className="overlay">
                    <div className="icon">
                      <EditOutlinedIcon fontSize="large" className='inner-icon'
                        onClick={(event) => {
                          event.stopPropagation();
                          navigate(`/admin/new-ads/${item?._id}`);
                        }} />
                      <DeleteOutlinedIcon fontSize="large" className='inner-icon'
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteDialogOpen(item?._id);
                        }} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', flexWrap: 'wrap', alignItem: 'center', marginTop: '20px' }}>
                      <h4 style={{ margin: '0px' }}>{item?.type}</h4>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <SellIcon style={{ color: '#B5E4CA' }} />
                        <Card sx={{ px: 1, backgroundColor: '#B5E4CA', boxShadow: 'none' }}><h4 style={{ margin: '0px' }}>{item?.plan} months</h4></Card>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <AspectRatioIcon sx={{ color: '#FAAF00' }} />
                        <h4 style={{ margin: '0px' }}>{item?.size}</h4>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <HourglassTopIcon color='error' />
                        <h4 style={{ margin: '0px' }}>{moment(item?.expiryDate).format("DD/MM/YYYY")}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </PhotoView>
            </PhotoProvider>
          </Item>
        </Grid>
      ))}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={deleteDialog}
        onClose={() => deleteDialogCancel()}
      >
        <DialogTitle>
          Are you sure you want to remove this Ad?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", gap: 2, flexWrap: 'wrap' }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { deleteClick(adsId) }}>YES</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={deleteDialogCancel} >NO</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },
          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },
          },
        }}
      />
    </Grid>
  );
}