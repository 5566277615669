import { Box, Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import LatestSession from './NewsSessionComponents/LatestSession';

const Item = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  bgcolor: '#fff',
}));

const AdvertiseItem = ({ District, image, description, Contact }) => (
  <div>
    <div>
      <img alt={`Profile ${District}`} src={image} style={{ width: "100%" }} />
    </div>
    <div>
      <p style={{ fontSize: 17, fontWeight: 'bold', fontFamily: 'Noto Sans Malayalam, sans-serif', margin: 10 }}>{District}</p>
      <p style={{ fontSize: 15, fontFamily: 'Noto Sans Malayalam, sans-serif', margin: 10 }}>{description}</p>
      <p style={{ fontSize: 15, fontFamily: 'Noto Sans Malayalam, sans-serif', margin: 10 }}>Contact: {Contact}</p>
    </div>
  </div>
);

function BuysellComponent() {
  const style = {
    marginBottom: "20px"
  };
        
  const profiles = [
    { image: "/UserSideImages/buy3.png", District: "Kollam", description: "Plot for Sale.", Contact: "123-456-7890" },
    { image: "/UserSideImages/buy4.png", District: "Alappuzha", description: "House for Sale.", Contact: "987-654-3210" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "Plot for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "Plot for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "House for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "House for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "House for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Alappuzha", description: "House for Sale.", Contact: "555-123-4567" },
    { image: "/UserSideImages/buy5.png", District: "Kottayam", description: "House for Sale.", Contact: "555-123-4567" },
    // ... Add more profiles with updated descriptions as needed
  ];

  return (
    <Grid container spacing={5}>
      {/* First Card */}
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Grid container spacing={5}>
          {profiles.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
              <Item>
                <AdvertiseItem {...item} />
              </Item>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <LatestSession />
        <Box style={style}>
          <img alt="complex" src={"/UserSideImages/img_ad5.jpg"} style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box style={style}>
          <img alt="complex" src={"/UserSideImages/img-ad2.jpg"} style={{ width: '100%', height: '100%' }} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default BuysellComponent;
