import { Button, Grid } from '@mui/material';
import React from "react";

function BuySellBox() {
  return (
    // <Grid container spacing={2}>
    <Grid container xs={12} sm={12} md={12} lg={12} justifyContent="center" alignItems="center">
        {/* Your button goes here */}
        <Button variant="contained" color="primary">
          BUY & SELL
        </Button>
      </Grid>
    
  );
}

export default BuySellBox;
