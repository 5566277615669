// import React, { useEffect, useState } from 'react'
// import { Box, Button, Card, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
// import styled from '@emotion/styled';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { useNavigate, useParams } from 'react-router-dom';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
// import { getAdsById, postAds, updateAds } from '../../../../../Services/AdminServices';
// import toast, { Toaster } from 'react-hot-toast';

// const VisuallyHiddenInput = styled('input')({
//     clip: 'rect(0 0 0 0)',
//     clipPath: 'inset(50%)',
//     height: 1,
//     overflow: 'hidden',
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     whiteSpace: 'nowrap',
//     width: 1,
// });

// const card = styled(Card)(({ theme }) => ({
//     backgroundColor: "#fff",
//     boxShadow: 'none',
//     marginBottom: 20,
//     width: '70%',
//     '@media (max-width: 1200px)': {
//         width: '70%', // Adjust width for desktop
//     },

//     '@media (max-width: 992px)': {
//         width: '100%', // Adjust width for tablets
//     },
//     '@media (max-width: 768px)': {
//         width: '100%',// Adjust width for Mobiles
//     },
// }));

// function NewAds() {
//     const navigate = useNavigate();
//     const { adsId } = useParams();

//     const [image, setImage] = useState(null);
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [type, setType] = useState("");
//     const [size, setSize] = useState("");
//     const [plan, setPlan] = useState("");
//     const [expiryDate, setExpiryDate] = useState(null);
//     const [customerDetails, setCustomerDetails] = useState("");
//     const [link, setLink] = useState("");

//     const [imageError, setImageError] = useState(false);
//     const [typeError, setTypeError] = useState(false);
//     const [sizeError, setSizeError] = useState(false);
//     const [planError, setPlanError] = useState(false);
//     const [expiryError, setExpiryError] = useState(false);

//     const fileChange = (e) => {
//         const file = e.target.files[0];
//         setImage(file);
//         const reader = new FileReader();
//         reader.onload = function (event) {
//             const img = new Image();
//             img.src = event.target.result;
//             img.onload = function () {
//                 const width = this.width;
//                 const height = this.height;
//                 const sizeString = `${width}X${height}`;
//                 // Assuming setImageSize is a function to set the image size object
//                 setSize(sizeString);
//                 // Setting selectedImage
//                 setSelectedImage(URL.createObjectURL(file));
//             };
//         };
//         reader.readAsDataURL(file);
//     }

//     const planChange = (e) => {
//         setPlan(e.target.value);
//         const selectedPlan = parseInt(e.target.value);
//         const currentDate = new Date();
//         const futureDate = new Date(currentDate);
//         futureDate.setMonth(currentDate.getMonth() + selectedPlan);
//         setExpiryDate(dayjs(futureDate))
//     }

//     const dateChange = (date) => {
//         setExpiryDate(date);
//     }

//     const saveClick = async () => {
//         let error = false;
//         if (!adsId && image) {
//             if (image == null) {
//                 setImageError(true);
//                 error = true;
//             } else {
//                 setImageError(false);
//             }
//         }
//         if (type === "") {
//             setTypeError(true);
//             error = true;
//         } else {
//             setTypeError(false);
//         }
//         if (size === "") {
//             setSizeError(true);
//             error = true;
//         } else {
//             setSizeError(false);
//         }
//         if (plan === "") {
//             setPlanError(true);
//             error = true;
//         } else {
//             setPlanError(false);
//         }
//         if (expiryDate == null) {
//             setExpiryError(true);
//             error = true;
//         } else {
//             setExpiryError(false);
//         }
//         if (error) return;

//         const formData = new FormData();
//         if (!adsId || image) {
//             formData.append("image", image);
//         }
//         formData.append("type", type);
//         formData.append("size", size);
//         formData.append("plan", plan);
//         formData.append("expiryDate", expiryDate);
//         formData.append("customerDetails", customerDetails);
//         formData.append("link", link);

//         if (adsId) {
//             await updateAds(adsId, formData).then((res) => {
//                 toast.success(res?.data?.message);
//                 setTimeout(() => {
//                     navigate('/admin/ads');
//                 }, 2000);
//             }).catch((err) => { toast.error(err.response.data.message) })
//         } else {
//             await postAds(formData).then((res) => {
//                 toast.success(res?.data?.message);
//                 setTimeout(() => {
//                     navigate('/admin/ads');
//                 }, 2000);
//             }).catch((err) => { toast.error(err.response.data.message) })
//         }
//     }

//     useEffect(() => {
//         if (adsId) {
//             getAdsById(adsId).then((res) => {
//                 let data = res?.data?.ad;
//                 setSelectedImage(data?.image?.url);
//                 setType(data?.type);
//                 setSize(data?.size);
//                 setPlan(data?.plan);
//                 setExpiryDate(dayjs(data?.expiryDate));
//                 setCustomerDetails(data?.customerDetails);
//                 setLink(data?.link);
//             }).catch((err)=>{ console.log("Error:", err)})
//         }
//     }, []);

//     return (
//         <div>
//             <Box>
//                 <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
//                     {adsId ? "Edit Ad" : "New Ad"}
//                 </Typography>
//             </Box>
//             <Box component={card}>
//                 <Box className="section-new-category-add-information-header-flex">
//                     <div className='new-category-add-information-icon'></div>
//                     <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
//                         {adsId ? "Edit Information" : "Add Information"}
//                     </Typography>
//                 </Box>
//                 <Box className="new-category-padding" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                     {!selectedImage ?
//                         <Paper variant="outlined" square style={{ border: imageError ? '1px solid red' : '1px solid grey' }}>
//                             <img src="/upload.png" loading="lazy" alt="images" style={{ height: "110px", width: "175px", marginTop: '5px', marginLeft: '5px', marginRight: '5px', objectFit: 'contain' }} />
//                         </Paper>
//                         :
//                         <img
//                             src={selectedImage}
//                             alt="Image"
//                             style={{ width: '100%', objectFit: 'cover', marginBottom: '20px', marginRight: '5px' }}
//                         />
//                     }
//                 </Box>
//                 <Box className="new-category-padding" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                     <Button
//                         component="label"
//                         variant="contained"
//                         startIcon={<CloudUploadIcon />}
//                     >
//                         Upload file
//                         <VisuallyHiddenInput type="file" onChange={(e) => { fileChange(e) }} />
//                     </Button>
//                 </Box>
//                 <Box className="new-category-padding">
//                     <FormControl fullWidth sx={{ mb: 5 }} required={true} error={typeError}>
//                         <InputLabel id="demo-simple-select-label">Type</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             label="Type"
//                             value={type}
//                             onChange={(e) => { setType(e.target.value) }}
//                         >
//                             <MenuItem value={"box"}>Box</MenuItem>
//                             <MenuItem value={"banner"}>Banner</MenuItem>
//                             <MenuItem value={"premium"}>Premium</MenuItem>
//                         </Select>
//                     </FormControl>
//                     <TextField
//                         error={sizeError}
//                         required={true}
//                         disabled={true}
//                         id="outlined-basic"
//                         label="Size"
//                         variant="outlined"
//                         sx={{ mb: 5 }}
//                         fullWidth={true}
//                         value={size}
//                         onChange={(e) => { setSize(e.target.value) }} />
//                     <FormControl fullWidth sx={{ mb: 5 }} required={true} error={planError}>
//                         <InputLabel id="demo-simple-select-label">Plan</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={plan}
//                             label="Plan"
//                             onChange={(e) => { planChange(e) }}
//                         >
//                             <MenuItem value={"3"}>3 months</MenuItem>
//                             <MenuItem value={"6"}>6 months</MenuItem>
//                             <MenuItem value={"12"}>12 months</MenuItem>
//                         </Select>
//                     </FormControl>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                         <DatePicker
//                             error={expiryError}
//                             label="Expiry Date"
//                             sx={{ mb: 5, width: '100%' }}
//                             disabled={true}
//                             value={expiryDate}
//                             onChange={(newValue) => { dateChange(newValue) }}
//                             slotProps={{
//                                 textField: {
//                                     error: expiryError,
//                                     required: true
//                                 },
//                             }}
//                         />
//                     </LocalizationProvider>
//                     <TextField
//                         id="outlined-basic"
//                         label="Customer Details"
//                         variant="outlined"
//                         sx={{ mb: 5 }}
//                         fullWidth={true}
//                         value={customerDetails}
//                         onChange={(e) => { setCustomerDetails(e.target.value) }} />
//                     <TextField
//                         id="outlined-basic"
//                         label="Link"
//                         variant="outlined"
//                         sx={{ mb: 5 }}
//                         fullWidth={true}
//                         value={link}
//                         onChange={(e) => { setLink(e.target.value) }} />
//                 </Box>
//                 <Box className="new-category-padding">
//                     <Button
//                         variant='contained'
//                         style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10, fontSize: 15 }}
//                         onClick={() => { saveClick() }}
//                     >{adsId ? "Update" : "Save"}</Button>
//                 </Box>
//             </Box>
//             <Toaster
//                 position="top-center"
//                 reverseOrder={false}
//                 toastOptions={{
//                     success: {
//                         style: {
//                             background: 'rgb(46, 125, 50)',
//                             color: 'white'
//                         },
//                         iconTheme: {
//                             primary: 'rgb(46, 125, 50)',
//                             secondary: 'white',
//                         },

//                     },
//                     error: {
//                         style: {
//                             background: 'rgb(211, 47, 47)',
//                             color: 'white'
//                         },
//                         iconTheme: {
//                             primary: 'rgb(211, 47, 47)',
//                             secondary: 'white',
//                         },

//                     },
//                 }}
//             />
//         </div>
//     )
// }

// export default NewAds;



import React, { useEffect, useState } from 'react';
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate, useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { getAdsById, postAds, updateAds } from '../../../../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

// Define fixed sizes for each ad type
const adSizes = {
  box: { width: null, height: null }, 
  banner: { width: 728, height: 90 },
  premium: { width: 728, height: 90 },
};

function NewAds() {
  const navigate = useNavigate();
  const { adsId } = useParams();

  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [plan, setPlan] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [customerDetails, setCustomerDetails] = useState("");
  const [link, setLink] = useState("");

  const [imageError, setImageError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const width = this.width;
          const height = this.height;
          const expectedSize = adSizes[type];

          if (type === 'box' || (width === expectedSize.width && height === expectedSize.height)) {
            setImage(file);
            setSize(`${width}X${height}`);
            setSelectedImage(URL.createObjectURL(file));
            setImageError(false);
          } else {
            setImageError(true);
            toast.error(`Image size must be ${expectedSize.width}x${expectedSize.height} pixels for ${type} ads.`);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const typeChange = (e) => {
    const newType = e.target.value;
    setType(newType);
    // Reset size and image when type changes
    setSize(newType === 'box' ? '' : `${adSizes[newType].width}X${adSizes[newType].height}`);
    setImage(null);
    setSelectedImage(null);
  };

  const planChange = (e) => {
    setPlan(e.target.value);
    const selectedPlan = parseInt(e.target.value);
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + selectedPlan);
    setExpiryDate(dayjs(futureDate));
  };

  const dateChange = (date) => {
    setExpiryDate(date);
  };

  const saveClick = async () => {
    let error = false;
    if (!adsId && !image) {
      setImageError(true);
      error = true;
    } else {
      setImageError(false);
    }
    if (type === "") {
      setTypeError(true);
      error = true;
    } else {
      setTypeError(false);
    }
    if (size === "" && type !== 'box') {
      setSizeError(true);
      error = true;
    } else {
      setSizeError(false);
    }
    if (plan === "") {
      setPlanError(true);
      error = true;
    } else {
      setPlanError(false);
    }
    if (expiryDate == null) {
      setExpiryError(true);
      error = true;
    } else {
      setExpiryError(false);
    }
    if (error) return;

    const formData = new FormData();
    if (!adsId || image) {
      formData.append("image", image);
    }
    formData.append("type", type);
    formData.append("size", size);
    formData.append("plan", plan);
    formData.append("expiryDate", expiryDate);
    formData.append("customerDetails", customerDetails);
    formData.append("link", link);

    try {
      if (adsId) {
        const res = await updateAds(adsId, formData);
        toast.success(res?.data?.message);
      } else {
        const res = await postAds(formData);
        toast.success(res?.data?.message);
      }
      setTimeout(() => {
        navigate('/admin/ads');
      }, 2000);
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    if (adsId) {
      getAdsById(adsId).then((res) => {
        let data = res?.data?.ad;
        setSelectedImage(data?.image?.url);
        setType(data?.type);
        setSize(data?.size);
        setPlan(data?.plan);
        setExpiryDate(dayjs(data?.expiryDate));
        setCustomerDetails(data?.customerDetails);
        setLink(data?.link);
      }).catch((err) => { 
        console.log("Error:", err);
        toast.error("Failed to fetch ad details");
      });
    }
  }, [adsId]);

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          {adsId ? "Edit Ad" : "New Ad"}
        </Typography>
      </Box>
      <Box component={card}>
        <Box className="section-new-category-add-information-header-flex">
          <div className='new-category-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {adsId ? "Edit Information" : "Add Information"}
          </Typography>
        </Box>
        <Box className="new-category-padding">
          <FormControl fullWidth sx={{ mb: 5 }} required={true} error={typeError}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              label="Type"
              value={type}
              onChange={typeChange}
            >
              <MenuItem value="box">Box</MenuItem>
              <MenuItem value="banner">Banner</MenuItem>
              <MenuItem value="premium">Premium</MenuItem>
            </Select>
          </FormControl>
          {type && type !== 'box' && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Required image size: {adSizes[type].width}x{adSizes[type].height} pixels
            </Typography>
          )}
          <Box className="new-category-padding" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {!selectedImage ? (
              <Paper variant="outlined" square style={{ border: imageError ? '1px solid red' : '1px solid grey' }}>
                <img src="/upload.png" loading="lazy" alt="upload placeholder" style={{ height: "110px", width: "175px", marginTop: '5px', marginLeft: '5px', marginRight: '5px', objectFit: 'contain' }} />
              </Paper>
            ) : (
              <img
                src={selectedImage}
                alt="Selected Ad"
                style={{ width: '100%', objectFit: 'cover', marginBottom: '20px', marginRight: '5px' }}
              />
            )}
          </Box>
          <Box className="new-category-padding" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={!type}
            >
              Upload file
              <VisuallyHiddenInput type="file" onChange={fileChange} accept="image/*" />
            </Button>
          </Box>
          <TextField
            error={sizeError}
            required={true}
            disabled={true}
            id="outlined-basic"
            label="Size"
            variant="outlined"
            sx={{ mb: 5, mt: 5 }}
            fullWidth={true}
            value={size}
          />
          <FormControl fullWidth sx={{ mb: 5 }} required={true} error={planError}>
            <InputLabel id="plan-select-label">Plan</InputLabel>
            <Select
              labelId="plan-select-label"
              id="plan-select"
              value={plan}
              label="Plan"
              onChange={planChange}
            >
              <MenuItem value="3">3 months</MenuItem>
              <MenuItem value="6">6 months</MenuItem>
              <MenuItem value="12">12 months</MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expiry Date"
              sx={{ mb: 5, width: '100%' }}
              disabled={true}
              value={expiryDate}
              onChange={dateChange}
              slotProps={{
                textField: {
                  error: expiryError,
                  required: true
                },
              }}
            />
          </LocalizationProvider>
          <TextField
            id="customer-details"
            label="Customer Details"
            variant="outlined"
            sx={{ mb: 5 }}
            fullWidth={true}
            value={customerDetails}
            onChange={(e) => setCustomerDetails(e.target.value)}
          />
          <TextField
            id="link"
            label="Link"
            variant="outlined"
            sx={{ mb: 5 }}
            fullWidth={true}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Box>
        <Box className="new-category-padding">
          <Button
            variant='contained'
            style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10, fontSize: 15 }}
            onClick={saveClick}
          >
            {adsId ? "Update" : "Save"}
          </Button>
        </Box>
      </Box>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },
          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },
          },
        }}
      />
    </div>
  );
}

export default NewAds;