import React from 'react';
import BuysellComponent from '../../Components/BuysellComponent';
import BuySellBox from '../../Components/Box/BuySellBox';

function BuyandSell() {

  return (
    <div className='test'>
      <div style={{ paddingTop: 40 }}>
        <div style={{ paddingBottom: 40 }}>
          <BuySellBox />
        </div>
      </div>
      <div style={{ paddingBottom: 140 }}>
        <BuysellComponent />
      </div>
    </div>
  )
}

export default BuyandSell;