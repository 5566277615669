import React, { useEffect, useState } from 'react'
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategoryById, postCategory, updateCategory } from '../../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';

const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },

  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%',// Adjust width for Mobiles
  },
}));

function NewCategory() {
  const navigate = useNavigate();
  const { catId } = useParams();

  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState(false);
  const [main, setMain] = useState("");
  const [mainError, setMainError] = useState(false);

  useEffect(() => {
    if (catId) {
      getCategoryById(catId).then((res) => {
        let data = res?.data?.category;
        setCategoryName(data?.categoryName);
        setMain(data?.type);
      }).catch((err)=>{ console.log("Error:", err)})
    }
  }, [])


  const saveClick = async () => {
    let error = false;
    if (categoryName === "") {
      setCategoryNameError(true);
      error = true;
    } else {
      setCategoryNameError(false);
    }
    if (main === "") {
      setMainError(true);
      error = true;
    } else {
      setMainError(false);
    }
    if (error) return;

    let data = {
      categoryName: categoryName,
      type: main
    }
    if (catId) {
      await updateCategory(catId, data).then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate('/admin/category');
        }, 2000);
      }).catch((err) => { toast.error(err.response.data.message) })
    } else {
      await postCategory(data).then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate('/admin/category');
        }, 2000);
      }).catch((err) => { toast.error(err.response.data.message) })
    }
  }

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          {catId ? "Edit" : "New"} Category
        </Typography>
      </Box>
      <Box component={card}>
        <Box className="section-new-category-add-information-header-flex">
          <div className='new-category-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {catId ? "Edit" : "Add"} Information
          </Typography>
        </Box>
        <Box className="new-category-padding">
          <TextField
            error={categoryNameError}
            id="outlined-basic"
            label="Category Name"
            placeholder='Category Name'
            variant="outlined"
            sx={{ mb: 5 }}
            fullWidth={true}
            required={true}
            value={categoryName}
            onChange={(e) => { setCategoryName(e.target.value) }} />
          {/* <TextField
            error={mainError}
            id="outlined-basic"
            label="Type"
            placeholder='Type'
            variant="outlined"
            sx={{ mb: 5 }}
            fullWidth={true}
            required={true}
            value={main}
            onChange={(e) => { setMain(e.target.value) }} /> */}
          <FormControl fullWidth sx={{ mb: 5 }} required={true} error={mainError}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Type"
              value={main}
              onChange={(e) => { setMain(e.target.value) }}
            >
              <MenuItem value={"Main"}>Main</MenuItem>
              <MenuItem value={"Sub"}>Sub</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="new-category-padding">
          <Button
            variant='contained'
            style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10, fontSize: 15 }}
            onClick={() => { saveClick() }}
          >{catId ? "Update" : "Save"}</Button>
        </Box>
      </Box>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },

          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div>
  )
}

export default NewCategory;