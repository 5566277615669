import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';

import NewsDetail from '../../Components/DetailNewsAdd/NewsDetail';
import MatrimonialAds
    from '../../Components/DetailNewsAdd/MatrimonialAds';
import Latestnews from '../../Components/DetailNewsAdd/Latestnews';
import LatestSession from '../../Components/NewsSessionComponents/LatestSession';
import { getAllBox, getNewsById, getNewsBySlugId, updateNewsCount, updateNewsCountBySlug } from '../../../Services/UserServices';
import { useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';

function DetailNews() {
    const style = {
        marginBottom: "20px"
    }
    const { newsId } = useParams();

    const [news, setNews] = useState(null);
    const [ads, setAds] = useState([]);

    // Loader
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        window.scrollTo(0,0)
        try {
            await getNewsBySlugId(newsId).then((res) => {
                let data = res?.data?.news;
                setNews(data);
                // Transform the content array
                // const transformedContent = [];
                // let currentContent = {};
                // data?.content.forEach(item => {
                //     switch (item.type) {
                //         case 'header':
                //             if (currentContent.header) {
                //                 transformedContent.push({ ...currentContent });
                //                 currentContent = {};
                //             }
                //             currentContent.header = item;
                //             break;
                //         case 'image':
                //             currentContent.image = item;
                //             break;
                //         case 'paragraph':
                //             currentContent.paragraph = item;
                //             break;
                //         case 'Embed':
                //             currentContent.Embed = item;
                //             break;
                //         case 'attaches':
                //             currentContent.attaches = item;
                //             break;
                //         case 'linkTool':
                //             currentContent.linkTool = item;
                //             break;
                //         case 'list':
                //             currentContent.list = item;
                //             break;
                //         default:
                //             break;
                //     }
                // })
                // if (Object.keys(currentContent).length !== 0) {
                //     transformedContent.push({ ...currentContent });
                // }

                // // Reassign the transformed content array back to the newsData object
                // if (data?.content) {
                //     data.content = transformedContent;
                // }
                // setNews(data);
                return getAllBox();
            }).then((res) => {
                let data = res?.data?.ads;
                setAds(data);
                return updateNewsCountBySlug(newsId);
            }).then((res) => { })
        } catch (err) {
            setLoading(false);
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <Loader />
    }

    return (
        <Grid container spacing={2} style={{ paddingTop: 50 }}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                <NewsDetail news={news} />
                <MatrimonialAds />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: 20 }}>
                    <Grid container spacing={5}>
                        {news?.adSet?.ads.map((ad, index) => (
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <img
                                    key={index}
                                    alt={`Ad ${index + 1}`}
                                    src={ad?.image?.url}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {news?.categoryName !== "Matrimony" && (
                    <Latestnews news={news} />
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <LatestSession />
                {ads.map((ads, index) => (
                    <Box style={style} key={index}>
                        <img alt="complex" src={ads?.image?.url} style={{ width: '100%', height: '100%' }} />
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}

export default DetailNews;