import React from 'react'
import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveToLocalStorage } from '../../../Helper/helper';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20
}));

function NewsDetail({ news }) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');


    // ShareOption
    const whatsAppShare = (news) => {
        let localHost = window.location.origin;
        let link = `${localHost}/user/news/details/${news?.slug}`;
        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(link)}`;
        // Open WhatsApp link in a new tab
        window.open(whatsappLink, '_blank');
    }

    const facebookShare = (news) => {
        let localHost = window.location.origin;
        let link = `${localHost}/user/news/details/${news?.slug}`;
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=&quote=${encodeURIComponent(link)}`;
        // Open Facebook share link in a new tab
        window.open(facebookLink, '_blank');
    }

    const convertDataToHtml = (blocks) => {
        // var convertedHtml = ``;
        // if (!blocks || !Array.isArray(blocks)) return convertedHtml = ``;
        // blocks.map(block => {
        //     switch (block?.type) {
        //         case "header":
        //             convertedHtml += `<h${block?.data?.level}>${block?.data?.text}</h${block?.data?.level}>`;
        //             break;
        //         case "Embed":
        //             convertedHtml += `<div><iframe style="width:100%;height:500px" src="${block?.data?.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div><br/>`;
        //             break;
        //         case "paragraph":
        //             convertedHtml += `<p class="news-details-description-text-style">${block?.data?.text}</p>`;
        //             break;
        //         case "delimiter":
        //             convertedHtml += "<hr />";
        //             break;
        //         case "image":
        //             convertedHtml += `<img style="width:100%; height: 100%" src="${block?.data?.file?.url}" title="${block?.data?.caption}" /><br /><em>${block?.data?.caption}</em><br/>`;
        //             break;
        //         case "list":
        //             convertedHtml += "<ul>";
        //             block?.data.items.forEach(function (li) {
        //                 convertedHtml += `<li>${li}</li>`;
        //             });
        //             convertedHtml += "</ul>";
        //             break;
        //         case "attaches":
        //             convertedHtml += `<div style="display: inline-flex; align-item:center; gap:20px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); padding: 20px;border-radius:5px">
        //                                 <svg
        //                                 style="width:20px;height:20px"
        //                                     aria-describedby="desc"
        //                                     aria-labelledby="title"
        //                                     role="img"
        //                                     viewBox="0 0 64 64"
        //                                     xmlns="http://www.w3.org/2000/svg"
        //                                     xmlnsXlink="http://www.w3.org/1999/xlink"
        //                                 >
        //                                 <path
        //                                     d="M54.5 29.3A16 16 0 0 0 31.9 6.7L8.3 30.2a2 2 0 0 0 2.9 2.8L34.7 9.5a12 12 0 0 1 17 17L22.5 55.7a8 8 0 1 1-11.3-11.3L37.5 18a4 4 0 1 1 5.7 5.7L22.5 44.4a2 2 0 0 0 2.8 2.8L46 26.5a8 8 0 1 0-11.3-11.3L8.3 41.5a12 12 0 0 0 17 17z"
        //                                     data-name="layer1"
        //                                     stroke="#202020"
        //                                     strokeLinecap="round"
        //                                     strokeLinejoin="round"
        //                                     strokeMiterlimit="10"
        //                                     strokeWidth={2}
        //                                 />
        //                                 </svg>
        //                                 <a href="${block?.data?.file?.url}" target="_blank" style="text-decoration: none">${block?.data?.title ? block?.data?.title : "Attachment"}</a>
        //                                 </div>`;
        //             break;
        //         case "linkTool":
        //             convertedHtml += `<div ><a href=${block?.data?.link}>${block?.data?.link}</a></div>`;
        //             break;
        //         default:
        //             console.log("Unknown block type", block?.type);
        //             break;
        //     }
        // });
        return <div className="custom-html-content-news-Details" dangerouslySetInnerHTML={{ __html: blocks }}></div>;
    }

    // const loadNews = (news) => {
    //     return (
    //         <div style={{ position: 'relative' }}>
    //             {news?.content.map((e, i) => (
    //                 <Box sx={boxStyle} key={i}>
    //                     <img alt="complex" src={e?.image?.data?.file?.url} style={{ width: '100%', height: '100%' }} />
    //                     <Box style={{ flex: 1 }}>
    //                         <div className='card-content'>
    //                             <p className="news-details-title-text-header" dangerouslySetInnerHTML={{ __html: e?.header?.data?.text }}>
    //                                 {/* {e?.header?.data?.text} */}
    //                             </p>
    //                             <p className='news-details-description-text-style' dangerouslySetInnerHTML={{ __html: e?.paragraph?.data?.text }}>
    //                                 {/* {e?.paragraph?.data?.text} */}
    //                             </p>

    //                         </div>
    //                     </Box>

    //                 </Box>
    //             ))}
    //             <span className='category-card-green'>
    //                 {news?.categoryName}
    //             </span>
    //             <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: "20px", margin: '20px' }}>
    //                 <span className='worldFlex'>
    //                     <span className='worldChildFlex'>
    //                         <span>
    //                             <VisibilityOutlinedIcon />
    //                         </span>
    //                         <span>
    //                             <p className='date-time-text-style'>
    //                                 {news?.viewsCount}
    //                             </p>
    //                         </span>
    //                     </span>
    //                     <span className='worldChildFlex'>
    //                         <span>
    //                             <CalendarTodayOutlinedIcon />
    //                         </span>
    //                         <span>
    //                             <p className='date-time-text-style'>
    //                                 {moment(news?.date).format('DD-MM-YYYY')}
    //                             </p>
    //                         </span>
    //                     </span>
    //                 </span>
    //             </Card>
    //         </div>
    //     )
    // }

    return (
        <Item>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* {loadNews(news && news)} */}
                    <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                            <span className='news-details-category-card-green'>
                                {news?.categoryName}
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                <WhatsappIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { whatsAppShare(news) }} />
                                <FacebookIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { facebookShare(news) }} />
                                {status && (
                                    <Button sx={{ fontWeight: 'bold' }} onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/admin/edit-news/${news?.slug}`) }} size='small' variant="contained" disableElevation startIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>
                        <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: "10px", margin: '20px 0px 20px 0px' }}>
                            <span className='worldFlex'>
                                <span className='worldChildFlex'>
                                    <span>
                                        <VisibilityOutlinedIcon />
                                    </span>
                                    <span>
                                        <p className='date-time-text-style'>
                                            {news?.viewsCount}
                                        </p>
                                    </span>
                                </span>
                                <span className='worldChildFlex'>
                                    <span>
                                        <CalendarTodayOutlinedIcon />
                                    </span>
                                    <span>
                                        <p className='date-time-text-style'>
                                            {moment(news?.date).format('DD-MM-YYYY')}
                                        </p>
                                    </span>
                                </span>
                            </span>
                        </Card>
                        <Box>
                            <div>
                                <h3>{news?.title}</h3>
                            </div>
                            <div>
                                {convertDataToHtml(news?.content)}
                            </div>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </Item>
    )
}

export default NewsDetail; 