import React, { useEffect } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { finishLoadingUser } from "./redux/slices/userLoadingSlice";
import { setUser } from "./redux/slices/userSlice";
import { getCurrentUser } from './Services/AdminServices';
import UserRoutePages from './Routes/UserRoutes';
import NavBar from './User/Components/Navbar/NavBar';
import TabNavigation from './User/Components/Navbar/TabNavigation';
import ResponsiveDrawer from './Admin/Component/Drawer/Drawer';
import Footer from './User/Components/Footer/Footer';
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from './Helper/helper';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(() => {
  //   scrollTo(0, 0);
  // }, [location.pathname]);

  useEffect(() => {
    if (sessionStorage.getItem("authtoken")) {
      getCurrentUser()
        .then((response) => {
          dispatch(setUser(response?.data));
          dispatch(finishLoadingUser());
        })
        .catch((err) => {
          sessionStorage.removeItem("authtoken");
          dispatch(finishLoadingUser());
        });
    } else {
      dispatch(finishLoadingUser());
    }
  }, [dispatch]);

  if (location.pathname.includes("/admin")) {
    return (
      <div className="App">
        <ResponsiveDrawer />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="body-center">
        <div className="body-width">
          <NavBar />
          <TabNavigation />
          <UserRoutePages />
          <div style={{ paddingTop: 30 }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
