export const textTruncateMaxLength = (address, maxLength) => {
    if (address.length <= maxLength) {
        return address;
    } else {
        return address.slice(0, maxLength - 3) + '...';
    }
}

/**
 * Saves the value given to local storage
 * @param {string} key the key name
 * @param {string} value the value to store
 */
export const saveToLocalStorage = (key, value) => {
    if (window && window.localStorage) {
        if (!key.includes(`/user/news/details/`)) {
            window.localStorage.setItem(key, value)
        }
    }
}

/**
 * Gets the value from local storage
 * @param {string} key the key name
 */
export const getFromLocalStorage = (key) => {
    let result = null
    if (window && window.localStorage) {
        result = window.localStorage.getItem(key)
    }
    return result;
}

/**
 * Removes the value from local storage
 * @param {string} key the key name
 */
export const removeFromLocalStorage = (key) => {
    let result = null
    if (window && window.localStorage) {
        result = window.localStorage.removeItem(key)
    }
    return result;
}

/**
 * Scrolls to the last visited item and clears the residue
 */
export const scrollToLastVisitedItem = (timeout = 500) => {
    setTimeout(() => {
        const lastClickedItem = getFromLocalStorage(window.location.pathname);
        if (lastClickedItem) {
            window.scrollTo({
                top: lastClickedItem,
                behavior: 'smooth'
            });
            removeFromLocalStorage(window.location.pathname);
        }
        // if (lastClickedItem) {
        //     const selectedElement = document.getElementById(lastClickedItem);
        //     if (selectedElement) {
        //         selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //         removeFromLocalStorage('last-clicked-item');
        //     }
        // }
    }, timeout);
}