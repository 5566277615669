import React from 'react';
import { Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Link } from 'react-router-dom';
import { saveToLocalStorage } from '../../../Helper/helper';

const AdvertiseItem = styled(Card)({
    height: '100%',
    width: '100%',
    backgroundColor: "#f2f1f1",
    boxShadow: 'none',
});

function Advertise({ bannerAds }) {
    const hasMultipleAds = bannerAds.length > 1;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <AdvertiseItem>
                    <Link to={`/user/matrimony`} onClick={()=>{saveToLocalStorage(window.location.pathname, window.scrollY);}}>
                        <img alt="Ad 1" src="/UserSideImages/matrimonial-redirect.jpeg" style={{ width: '100%', height: 150, borderRadius: 5 }} />
                    </Link>
                </AdvertiseItem>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <AdvertiseItem>
                    <div className="slide-container">
                        {hasMultipleAds ? (
                            <Zoom scale={0.4} defaultIndex={0}>
                                {bannerAds.map((each, index) => (
                                    <Link to={each?.link} key={index}>
                                        <img style={{ width: "100%", height: 150, borderRadius: 5 }} src={each?.image?.url} alt={`Ad ${index + 1}`} />
                                    </Link>
                                ))}
                            </Zoom>
                        ) : (
                            <Link to={bannerAds[0]?.link}>
                                <img style={{ width: "100%", height: 150, borderRadius: 5 }} src={bannerAds[0]?.image?.url} alt="Ad" />
                            </Link>
                        )}
                    </div>
                </AdvertiseItem>
            </Grid>
        </Grid>
    );
}

export default Advertise;
