import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserDashboard from '../User/Pages/Dashboard/Dasbboard';
import Matrimonypage from '../User/Pages/Matrimony/Matrimonypage';
import Obituary from '../User/Pages/Orbituary/Obituary';
import BuyandSell from '../User/Pages/BuyAndSell/BuyandSell';
import DetailNews from '../User/Pages/NewsDetails/DetailNews';
import CategoryNewsList from '../User/Pages/CategoryNewsList/CategoryNewsList';
import TermsAndConditions from '../User/Components/Footer/TermsAndConditions';
import Privacy from '../User/Components/Footer/Privacy';
import Support from '../User/Components/Footer/Support';
import Topics from '../User/Pages/Topics/Topics';
import AboutUs from '../User/Pages/AboutUS/AboutUs';

function UserRoutePages() {
    return (
        <Routes>
            <Route path="/" element={<UserDashboard />} />
            <Route path="/terms-and-condition" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/topics" element={<Topics />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/user/matrimony" element={<Matrimonypage />} />
            <Route path="/user/obituary" element={<Obituary />} />
            <Route path="/user/buyandsell" element={<BuyandSell />} />
            <Route path="/user/news/details/:newsId" element={<DetailNews />} />
            <Route path="/user/news/category/list" element={<CategoryNewsList  />} />
        </Routes>
    );
}

export default UserRoutePages;
