import React, { useEffect } from 'react'

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ margin: 20 }}>
      <h2>Support</h2>
    </div>
  )
}

export default Support;