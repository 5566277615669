import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFilteredNewsData } from '../../../Services/UserServices';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Img = styled('img')({
  margin: 'auto 0px auto 0px',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20
}));

function MatrimonialAds() {

  const ArticlesButton = {
    backgroundColor: '#3a98c9',
    fontFamily: 'Noto Sans Malayalam, sans-serif',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '1.5em',
    margin: '20px',
  }

  const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px"
  }

  const boxStyleSecondWithBorder = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    borderTop: '1px solid #e5e5e5',
    marginTop: '15px',
  }

  const navigate = useNavigate();
  const [filteredNews, setFilteredNews] = useState([]);

  const getData = async () => {
    let filter = {
      "categoryName": "Matrimony",
      "type": "sub",
      "count": 4
    }
    await getFilteredNewsData(filter).then((res) => {
      let data = res?.data?.latestNews;
      // Transform the content array
      // data.forEach(latest => {
      //   const transformedContent = [];
      //   let currentContent = {};
      //   latest?.content.forEach(item => {
      //     switch (item.type) {
      //       case 'header':
      //         if (currentContent.header) {
      //           transformedContent.push({ ...currentContent });
      //           currentContent = {};
      //         }
      //         currentContent.header = item;
      //         break;
      //       case 'image':
      //         currentContent.image = item;
      //         break;
      //       case 'paragraph':
      //         currentContent.paragraph = item;
      //         break;
      //       case 'Embed':
      //         currentContent.Embed = item;
      //         break;
      //       case 'attaches':
      //         currentContent.attaches = item;
      //         break;
      //       case 'linkTool':
      //         currentContent.linkTool = item;
      //         break;
      //       case 'list':
      //         currentContent.list = item;
      //         break;
      //       default:
      //         break;
      //     }
      //   })
      //   if (Object.keys(currentContent).length !== 0) {
      //     transformedContent.push({ ...currentContent });
      //   }

      //   // Reassign the transformed content array back to the newsData object
      //   if (latest?.content) {
      //     latest.content = transformedContent;
      //   }
      // })
      setFilteredNews(data);
    }).catch((err) => { console.log("Error:", err) })
  }

  useEffect(() => {
    getData();
  }, []);

  const navigateNewsDetails = (id) => {
    navigate(`/user/news/details/${id}`);
    window.location.reload();
    window.scroll(0,0);
  }

  const loadNews = (news) => {
    return (news?.map((matrimony, index) => {
      const renderFilteredContent = (htmlContent) => {
        let arr = [];
        parse(htmlContent, {
          replace: (domNode) => {
            arr.push(domNode);
            return null;
          },
        });

        const imageNode = arr.find((node) => node?.name === 'img');
        const descriptionNode = arr.find((node) => node?.type === "text");

        const image = imageNode ? (
          <img
            alt="News"
            src={imageNode?.attribs?.src || "/DefaultImages/matrimony_default_img.jpeg"}
            style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
          />
        ) : (
          <img
            alt="Default"
            src="/DefaultImages/matrimony_default_img.jpeg"
            style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
          />
        );

        const description = descriptionNode ? descriptionNode?.data : '';

        return { image, description };
      };

      const { image, description } = renderFilteredContent(matrimony?.content);
      return (
        <Box style={boxStyleSecondWithBorder} key={index}>
          {image}
          <Box>
            <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: matrimony?.title }} onClick={() => { navigateNewsDetails(matrimony?.slug) }}>
            </p>
            <p className='description-text-style'>{description}</p>
          </Box>
        </Box>
      )
    })
    )
  }

  return (filteredNews.length !== 0 && (
    <Item>
      <Box>
        <Button variant="contained" style={ArticlesButton}>Matrimonial Ads</Button>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={boxStyle2}>
            {loadNews(filteredNews)}
          </Box>
        </Grid>
      </Grid>
    </Item>
  ))
}

export default MatrimonialAds;