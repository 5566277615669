import React, { useEffect, useState } from 'react'
import { Box, Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFilteredNewsData } from '../../../Services/UserServices';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../../Helper/helper';
// import { saveToLocalStorage } from '../../../Helper/helper';

const Img = styled('img')({
    margin: 'auto 0px auto 0px',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20
}));

const LatestButton = {
    backgroundColor: '#176B87',
    fontFamily: 'Noto Sans Malayalam, sans-serif',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '1.5em',
}

const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px"
}

const boxStyleSecondFLex = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    wrap: 'wrap'
}


function LatestSession() {
    const navigate = useNavigate();

    const [filteredNews, setFilteredNews] = useState([]);

    const getData = async () => {
        let filter = {
            "categoryName": "",
            "type": "Main",
            "count": 5
        }
        await getFilteredNewsData(filter).then((res) => {
            let data = res?.data?.latestNews;
            // data.forEach(latest => {
            //     const transformedContent = [];
            //     let currentContent = {};
            //     latest?.content.forEach(item => {
            //         switch (item.type) {
            //             case 'header':
            //                 if (currentContent.header) {
            //                     transformedContent.push({ ...currentContent });
            //                     currentContent = {};
            //                 }
            //                 currentContent.header = item;
            //                 break;
            //             case 'image':
            //                 currentContent.image = item;
            //                 break;
            //             case 'paragraph':
            //                 currentContent.paragraph = item;
            //                 break;
            //             case 'Embed':
            //                 currentContent.Embed = item;
            //                 break;
            //             case 'attaches':
            //                 currentContent.attaches = item;
            //                 break;
            //             case 'linkTool':
            //                 currentContent.linkTool = item;
            //                 break;
            //             case 'list':
            //                 currentContent.list = item;
            //                 break;
            //             default:
            //                 break;
            //         }
            //     })
            //     if (Object.keys(currentContent).length !== 0) {
            //         transformedContent.push({ ...currentContent });
            //     }

            //     // Reassign the transformed content array back to the newsData object
            //     if (latest?.content) {
            //         latest.content = transformedContent;
            //     }
            // })
            setFilteredNews(data);
        }).catch((err) => { console.log("Error:", err) })
    }

    useEffect(() => {
        getData();
    }, []);

    const navigateNewsDetails = (id, slug) => {
        saveToLocalStorage(window.location.pathname, window.scrollY);
        navigate(`/user/news/details/${slug}`);
        window.location.reload();
    }

    const loadNews = (latest) => {
        return (latest.map((news, index) => {
            const renderFilteredContent = (htmlContent) => {
                let arr = [];
                parse(htmlContent, {
                    replace: (domNode) => {
                        arr.push(domNode);
                        return null;
                    },
                });

                const imageNode = arr.find((node) => node?.name === 'img');

                const image = imageNode ? (
                    <img
                        alt="News"
                        src={imageNode?.attribs?.src || "/DefaultImages/news_default_img.jpeg"}
                        style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
                    />
                ) : (
                    <img
                        alt="Default"
                        src="/DefaultImages/news_default_img.jpeg"
                        style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
                    />
                );

                return { image };
            };

            const { image } = renderFilteredContent(news?.content);
            return (
                <Box id={news?._id} style={boxStyleSecondFLex} key={index}>
                    {image}
                    <Box sx={{
                        width: {
                            xs: '100%',    // 100% width on extra small screens
                            sm: '100%',   // 200px width on small screens
                            lg: '230px',   // 230px width on medium screens
                        },
                    }}>
                        <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: news?.title }} onClick={() => { navigateNewsDetails(news?._id, news?.slug) }}></p>
                    </Box>
                </Box>
            )
        })
        )
    }
    return (
        <Item>
            <Box style={{ marginBottom: "10px" }}>
                <Button variant="contained" style={LatestButton} fullWidth={true}>LATEST</Button>
            </Box>
            <Box style={boxStyle2}>
                {loadNews(filteredNews)}
            </Box>
        </Item>
    )
}

export default LatestSession;