import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '@mui/icons-material/Home';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ArticleIcon from '@mui/icons-material/Article';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Collapse, Menu, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../../Services/AdminServices';
import { useDispatch, useSelector } from 'react-redux';
import { unsetUser } from '../../../redux/slices/userSlice';
import AdminRoutePages from '../../../Routes/AdminRoutes';
import toast, { Toaster } from 'react-hot-toast';

function ResponsiveDrawer(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);

    const drawerWidth = user && sessionStorage.getItem("authtoken") ? 240 : "50%";
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout().then((res) => {
            sessionStorage.removeItem("authtoken");
            dispatch(unsetUser());
            handleClose();
            toast.success(res?.data?.message);
            setTimeout(() => {
                navigate('/admin/login');
            }, 2000);
        });
    };

    const handleProfileClick = () => {
        navigate(`/admin/profile-settings`);
        handleClose();
    };

    const [newsMenu, setNewsMenu] = useState(false);
    const handleNewsClick = () => {
        setNewsMenu(!newsMenu);
    };

    const drawer = (
        <div>
            <img src="/logo.jpeg" loading="lazy" alt="images" style={{ width: '100%', height: '17%' }} />
            <Divider />
            <List sx={{ px: 1 }}>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ color: "#0e1555" }}
                        onClick={() => { navigate('/admin/dashboard'); if (mobileOpen) { handleDrawerToggle() } }}
                        selected={location.pathname === '/admin/dashboard'}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ color: "#0e1555" }}
                        onClick={() => { navigate('/admin/category'); if (mobileOpen) { handleDrawerToggle() } }}
                        selected={location.pathname.startsWith('/admin/category') || location.pathname.startsWith('/admin/new-category') || location.pathname.startsWith('/admin/edit-category')}>
                        <ListItemIcon>
                            <DynamicFeedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Category"} />
                    </ListItemButton>
                </ListItem>
                <ListItemButton
                    onClick={handleNewsClick}
                    sx={{ color: "#0e1555" }}
                    selected={location.pathname.startsWith('/admin/drafted-news') || location.pathname.startsWith('/admin/published-news') || location.pathname.startsWith('/admin/new-news') || location.pathname.startsWith('/admin/edit-news')}>
                    <ListItemIcon>
                        <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="News" />
                    {newsMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </ListItemButton>
                <Collapse in={newsMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItemButton
                            sx={{ pl: 4, color: "#0e1555" }}
                            onClick={() => { navigate('/admin/new-news'); if (mobileOpen) { handleDrawerToggle() } }}
                            selected={location.pathname.startsWith('/admin/new-news')}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Create News" />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, color: "#0e1555" }}
                            onClick={() => { navigate('/admin/drafted-news'); if (mobileOpen) { handleDrawerToggle() } }}
                            selected={location.pathname.startsWith('/admin/drafted-news')}>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Drafted News" />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, color: "#0e1555" }}
                            onClick={() => { navigate('/admin/published-news'); if (mobileOpen) { handleDrawerToggle() } }}
                            selected={location.pathname.startsWith('/admin/published-news')}>
                            <ListItemIcon>
                                <PublishIcon />
                            </ListItemIcon>
                            <ListItemText primary="Published News" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ color: "#0e1555" }}
                        onClick={() => { navigate('/admin/ads'); if (mobileOpen) { handleDrawerToggle() } }}
                        selected={location.pathname.startsWith('/admin/ads') || location.pathname.startsWith('/admin/new-ads')}>
                        <ListItemIcon>
                            <AdsClickIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Ads"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ color: "#0e1555" }}
                        onClick={() => { navigate('/admin/uploads'); if (mobileOpen) { handleDrawerToggle() } }}
                        selected={location.pathname === '/admin/uploads'}>
                        <ListItemIcon>
                            <CloudUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Uploads"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {user && sessionStorage.getItem("authtoken") ? (
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: "#ffffff"
                    }}
                >
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon sx={{ color: '#80868D' }} />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                        ></IconButton>
                        <Toolbar style={{ display: 'flex', flexDirection: "row", gap: 30, alignItems: 'center' }}>
                            {/* <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                            >
                                <MessageOutlinedIcon sx={{ color: '#80868D' }} />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                            >
                                <NotificationsNoneOutlinedIcon sx={{ color: '#80868D' }} />
                            </IconButton> */}
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                id="demo-positioned-button"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <Avatar alt="profile" src={user?.data?.avatar?.url} />
                            </IconButton>
                        </Toolbar>
                    </Toolbar>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </AppBar>
            ) : null}
            {!user && !sessionStorage.getItem("authtoken") ? (
                <AppBar
                    position="fixed"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: "#ffffff"
                    }}
                >
                    <img src="/UserSideImages/logo.png" loading="lazy" alt="images" style={{ width: '100%' }} />
                </AppBar>
            ) : null}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {!user && !sessionStorage.getItem("authtoken") ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <img src="/UserSideImages/logo.png" loading="lazy" alt="images" style={{ width: '50%' }} />
                        </div>
                    ) : drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, p: 3,
                    width: {
                        sm: `calc(100% - ${drawerWidth}px)`,
                        xs: `calc(100% - ${drawerWidth}px)`
                    }, backgroundColor: '#F4F4F4'
                }}
            >
                <Toolbar />
                <AdminRoutePages />
            </Box>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgb(46, 125, 50)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(46, 125, 50)',
                            secondary: 'white',
                        },

                    },
                    error: {
                        style: {
                            background: 'rgb(211, 47, 47)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(211, 47, 47)',
                            secondary: 'white',
                        },

                    },
                }}
            />
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    window: PropTypes.func,
};

export default ResponsiveDrawer;
