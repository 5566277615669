import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box, Button, Card, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Embed from '@editorjs/embed';
import LinkTool from '@editorjs/link';
import '../../../Styles/newNews.css'
import { getAllAddSet, getAllCategories, newsFileUpload, newsImageUpload, postNews } from '../../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import AttachesTool from '@editorjs/attaches';
import ColorPlugin from 'editorjs-text-color-plugin';
import moment from 'moment/moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  padding: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },

  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%',// Adjust width for Mobiles
  },
}));

function NewNews() {

  // const { quill, quillRef } = useQuill();
  const reactQuillRef = useRef(null);
  const navigate = useNavigate();
  const editorInstance = useRef(null);

  const [category, setCategory] = useState("");
  const [categoryError, setcategoryError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [editorContent, setEditorContent] = useState('');

  const [spinner, setSpinner] = useState(false);
  const [slug, setSlug] = useState("");

  const [allAddSets, setAllAddSets] = useState([]);
  const [allAddSetSelect, setAllAddSetSelect] = useState("");

  const handleAddSetSelectChange = (value) => {
    setAllAddSetSelect(value);
  }
  const [categories, setCategories] = useState(null);

  const categoryChange = (e) => {
    setCategory(e.target.value);
  }

  const handleSlugChange = (event) => {
    let value = event.target.value.replace(/[^\w]/g, '_');
    setSlug(value);
  }

  const handleChange = (value) => {
    setEditorContent(value);
  };

  const imageHandler = useCallback(async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
          setSpinner(true);
          // Make an HTTP request to send the file to the backend
          const res = await newsImageUpload(formData);
          const url = res?.data?.file?.url;

          if (url) {
            const quill = reactQuillRef.current.getEditor(); // Access the Quill instance

            if (quill) {
              const range = quill.getSelection(); // Get the current selection
              if (range) {
                quill.insertEmbed(range.index, "image", url); // Insert the image
                 // Ensure cursor is placed after the image to come next line
              setTimeout(() => {
                quill.setSelection(range.index + 1);
                quill.focus();
                setSpinner(false);
              }, 0);
              }
            }
          }
        } catch (error) {
          setSpinner(false);
          console.error("Image upload failed:", error);
        }
      }
    };
  }, []);


  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }], // fonts
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['blockquote', 'code-block'],
        [{ 'color': [] }, { 'background': [] }],
        ["undo", "redo"]
        // ['clean'] // Add a button to clear formatting
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const getCategories = async () => {
    try {
      await getAllCategories().then((res) => {
        let response = res?.data?.categories;
        setCategories(response);
        return getAllAddSet();
      }).then((resposne) => {
        setAllAddSets(resposne?.data?.adSets)
      })
    } catch (err) {
      console.log(err);
    }
  }

  const initializeEditor = async () => {
    editorInstance.current = new EditorJS({
      holderId: 'editorjs',
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3
          }
        },
        image: {
          class: Image,
          inlineToolbar: true,
          config: {
            uploader: {
              uploadByFile(file) {
                // Create a FormData object to send the file to the backend
                const formData = new FormData();
                formData.append('image', file);
                // Make an HTTP request to send the file to the backend
                return newsImageUpload(formData).then(data => {
                  // Return the URL of the uploaded image
                  return {
                    success: 1,
                    file: {
                      url: data?.data.file?.url,
                    }
                  };
                }).catch(error => {
                  toast.error(error.response.data.message);
                  return { success: 0 };
                });
              },
            }
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        //   video: {
        //     class: VideoTool,
        //     config: {
        //       endpoint: null, // Your backend endpoint for url data fetching,
        //     }
        // },
        attaches: {
          class: AttachesTool,
          config: {
            uploader: {
              uploadByFile(file) {
                // Create a FormData object to send the file to the backend
                const formData = new FormData();
                formData.append('file', file);
                // Make an HTTP request to send the file to the backend
                return newsFileUpload(formData).then(data => {
                  return {
                    success: 1,
                    file: {
                      url: data?.data?.file?.url,
                    }
                  };
                }).catch(error => {
                  toast.error(error.response.data.message);
                  return { success: 0 };
                });
              }
            }
          }
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: null, // Your backend endpoint for url data fetching,
          }
        },
        Embed: {
          class: Embed,
          inlineToolbar: false,
          config: {
            services: {
              youtube: true,
            }
          }
        },
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
            defaultColor: '#FF1300',
            type: 'text',
            customPicker: true // add a button to allow selecting any colour  
          }
        },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
            icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
          }
        },
      },
      data: {},
    });
    try {
      await editorInstance.current.isReady;
    } catch (reason) {
      console.log(`Editor.js initialization failed because of ${reason}`);
    }
  };

  useEffect(() => {
    // initializeEditor();
    getCategories();
  }, []);

  const saveNews = async (status) => {
    try {

      let error = false;
      if (category === "") {
        setcategoryError(true);
        error = true;
      } else {
        setcategoryError(false);
      }
      if (title === "") {
        setTitleError(true);
        error = true;
      } else {
        setTitleError(false);
      }
      if (error) return;

      // const savedData = await editorInstance.current.save();

      // const titleBlock = savedData.blocks.find((block) => block.type === 'header');
      // const title = titleBlock ? titleBlock.data.text : 'Untitled';

      let categor = categories.find(f => f?._id === category);

      let newSlug = "";
      let today = new Date();
      if (slug === "") {
        let name = title.replace(/[^\w]/g, '_');
        newSlug = name + "_" + moment(today).format('MMMM_DD_YYYY');
      } else {
        newSlug = slug;
      }

      let data = {
        category: categor?._id,
        categoryName: categor?.categoryName,
        type: categor?.type,
        title: title,
        content: editorContent,
        slug: newSlug,
        status: status,
        adSet: allAddSetSelect,
      }

      // let findHeader = data.content.find(block => block?.type === "header");
      // if (!findHeader) return toast.error("The news must have a header");

      // let findImage = data.content.find(block => block?.type === "image");
      // if (!findImage) return toast.error("The news must have a image");

      // let findParagraph = data.content.find(block => block?.type === "paragraph");
      // if (!findParagraph) return toast.error("The news must have a paragraph");

      await postNews(data).then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          if (status == 'draft') {
            navigate('/admin/drafted-news');
          }
          if (status == 'published') {
            navigate('/admin/published-news');
          }
        }, 2000);
      })

    } catch (err) {
      toast.error(err.response.data.message)
    }
  };

  // useEffect(() => {
  //   if (quill) {
  //     quill.on('text-change', (delta, oldDelta, source) => {
  //       console.log("1", 'Text change!');
  //       console.log("2", quill.getText()); // Get text only
  //       console.log("3", quill.getContents()); // Get delta contents
  //       console.log("4", quill.root.innerHTML); // Get innerHTML using quill
  //       console.log("5", quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
  //     });
  //   }
  // }, [quill]);

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Add News
        </Typography>
      </Box>
      <Box component={card}>
        <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
          <div className='new-title-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Title
          </Typography>
        </Box>
        <TextField
          error={titleError}
          id="outlined-basic"
          label="Title"
          placeholder='Title'
          variant="outlined"
          sx={{ mb: 5 }}
          fullWidth={true}
          required={true}
          value={title}
          onChange={(e) => { setTitle(e.target.value) }} />
        <Box className="section-new-category-add-news-category-flex">
          <div className='new-category-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            News Category
          </Typography>
        </Box>
        <FormControl fullWidth sx={{ mb: 5 }} required={true} error={categoryError}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            label="Category"
            onChange={(e) => { categoryChange(e) }}
          >
            {categories && categories.map((cat, i) => (
              <MenuItem key={i} value={cat?._id}>{cat?.categoryName}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="section-new-category-add-news-category-flex">
          <div className='overview-header-first-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Slug(If you leave it blank, it will be generated automatically.)
          </Typography>
        </Box>

        <TextField
          // error={slugError}
          // required={true}
          id="outlined-basic"
          label="Slug"
          variant="outlined"
          sx={{ mb: 5 }}
          fullWidth={true}
          value={slug}
          onChange={(e) => { handleSlugChange(e) }} />

        <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
          <div className='ad-set-first-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Ads Set
          </Typography>
        </Box>

        <FormControl fullWidth={true}>
          <InputLabel id="type-select-label">Ads Set</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            label="Ads Set"
            value={allAddSetSelect}
            onChange={(e) => { handleAddSetSelectChange(e.target.value) }}
            sx={{
              '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                height: 'auto',
                minHeight: '1.4375em',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }
            }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {allAddSets.map((set, index) => (
              <MenuItem value={set?._id} key={index}>{set?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box className="section-new-category-add-news-content-flex">
          <div className='new-category-view-icon'></div>
          <Typography variant="h6" className='new-category-font-bold'>
            News Content
          </Typography>
        </Box>
        <Box>
          {/* <div id="editorjs"></div> */}
          {/* <div style={{ width: '100%', height: 300,marginBottom:'50px' }}>
            <div ref={quillRef} />
          </div> */}
          <ReactQuill
            ref={reactQuillRef}
            value={editorContent}
            onChange={handleChange}
            modules={modules}
            theme="snow"
          />
          {spinner &&
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '20px'
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2A85FF",
              color: "white",
              borderRadius: 2,
              fontSize: 15,
              flex: '1 1 auto', // Allow buttons to grow and shrink as needed
              minWidth: '120px', // Ensure a minimum width for smaller screens
            }}
            onClick={() => saveNews('draft')}
          >
            Save to Draft
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              borderRadius: 2,
              fontSize: 15,
              flex: '1 1 auto',
              minWidth: '120px',
            }}
            onClick={() => saveNews('published')}
          >
            Publish News
          </Button>
        </Box>

      </Box>

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },

          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div>
  )
}

export default NewNews;