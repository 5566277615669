import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import { getAllBox, getFilteredNewsData } from '../../../Services/UserServices';
import styled from '@emotion/styled';
import LatestSession from '../../Components/NewsSessionComponents/LatestSession';
import { saveToLocalStorage, scrollToLastVisitedItem, textTruncateMaxLength } from '../../../Helper/helper';
import '../../../Styles/UserMatrimony.css'
import Loader from '../../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Item = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  bgcolor: '#fff',
}));

const style = {
  marginBottom: "20px"
}

function Matrimonypage() {
  const navigate = useNavigate();

  const [ads, setAds] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);

  // Loader
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    window.scrollTo(0,0)
    let filter = {
      "categoryName": "Matrimony",
      "type": "",
      "count": 0
    }
    await getAllBox().then((res) => {
      let data = res?.data?.ads;
      setAds(data);
      return getFilteredNewsData(filter);
    }).then((res) => {
      let data = res?.data?.latestNews;
      // Transform the content array
      // data.forEach(latest => {
      //   const transformedContent = [];
      //   let currentContent = {};
      //   latest?.content.forEach(item => {
      //     switch (item.type) {
      //       case 'header':
      //         if (currentContent.header) {
      //           transformedContent.push({ ...currentContent });
      //           currentContent = {};
      //         }
      //         currentContent.header = item;
      //         break;
      //       case 'image':
      //         currentContent.image = item;
      //         break;
      //       case 'paragraph':
      //         currentContent.paragraph = item;
      //         break;
      //       case 'Embed':
      //         currentContent.Embed = item;
      //         break;
      //       case 'attaches':
      //         currentContent.attaches = item;
      //         break;
      //       case 'linkTool':
      //         currentContent.linkTool = item;
      //         break;
      //       case 'list':
      //         currentContent.list = item;
      //         break;
      //       default:
      //         break;
      //     }
      //   })
      //   if (Object.keys(currentContent).length !== 0) {
      //     transformedContent.push({ ...currentContent });
      //   }

      //   // Reassign the transformed content array back to the newsData object
      //   if (latest?.content) {
      //     latest.content = transformedContent;
      //   }
      // })
      setFilteredNews(data);
      setLoading(false);
      scrollToLastVisitedItem();
    }).catch((err) => {
      setLoading(false);
      scrollToLastVisitedItem();
      console.log("Error:", err)
    })
  }

  useEffect(() => {
    getData();
  }, []);

  const AdvertiseItem = ({ item, index }) => {
    const renderFilteredContent = (htmlContent) => {
      let arr = [];
      parse(htmlContent, {
        replace: (domNode) => {
          arr.push(domNode);
          return null;
        },
      });

      const imageNode = arr.find((node) => node?.name === 'img');
      const descriptionNode = arr.find((node) => node?.type === "text");

      const image = imageNode ? (
        <img
          alt="News"
          src={imageNode?.attribs?.src || "/DefaultImages/matrimony_default_img.jpeg"}
          className='matrimony-image'
        />
      ) : (
        <img
          alt="Default"
          src="/DefaultImages/matrimony_default_img.jpeg"
          className='matrimony-image'
        />
      );

      const description = descriptionNode ? descriptionNode?.data : '';

      return { image, description };
    };

    const { image, description } = renderFilteredContent(item?.content);
    return (
      <div key={index}>
        <div>
          {image}
        </div>
        <div>
          <p className="matrimony-id-text" dangerouslySetInnerHTML={{ __html: item?.title }} onClick={() =>{saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/user/news/details/${item?.slug}`)}}>
          </p>
          <p className='matrimony-desc-text'>{description}</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <div className='matrimony-btn-flex'>
        {/* <Button variant="contained" color="primary" style={{ fontSize: 15,marginTop:20 }}>
          Brides & Grooms
        </Button> */}
      </div>
      <Grid container spacing={5} style={{ paddingTop: 20 }}>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Grid container spacing={5}>
            {filteredNews.length === 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ fontSize: 20, fontWeight: 'bold', padding: 20, textAlign: 'center' }}>No Records Found...</div>
              </Grid>
            ) : (
              filteredNews.map((item, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                  <Item>
                    <AdvertiseItem item={item} index={index} />
                  </Item>
                </Grid>
              )))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <LatestSession />
          {ads.map((ads, index) => (
            <Box style={style} key={index}>
              <img alt="complex" src={ads?.image?.url} style={{ width: '100%', height: '100%' }} />
            </Box>
          ))}
        </Grid>
      </Grid>

    </div>


  )
}

export default Matrimonypage;